<template>
  <div id="product-slide">
    <slide-heading
      :heading="slide.heading"
      :tool-tip="slide.tool_tip"
    />

    <div v-if="slide.image_urls.length && slide.description">
      <slide-image-and-description :slide="slide" />
    </div>

    <div v-else-if="slide.description">
      <div class="description">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="slideDescription" />
        <!--eslint-enable-->
      </div>
    </div>

    <div v-else-if="slide.image_urls.length">
      <div class="image-vert">
        <img :src="imageUrl" :alt="slide.image_alt_text">
      </div>
    </div>

    <product-options-confirm-modal
      :bus="bus"
      :loading="loading"
      @complete="productOptionConfirmed"
      @productAdded="productAdded"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Vue from 'vue'
import { logSlideProductAdded } from '@/components/StagelineV2/helper.js'
import * as DOMPurify from 'dompurify'

export default {
  name: 'ProductSlide',
  components: {
    ProductOptionsConfirmModal: () => import('@/components/StagelineV2/modals/ProductOptionsConfirmModal'),
    SlideImageAndDescription:   () => import('@/components/StagelineV2/slides/SlideImageAndDescription'),
    SlideHeading:               () => import('@/components/StagelineV2/shared/SlideHeading'),
  },
  props: {
    slide: Object,
    selectedJurisdiction: Object,
  },
  data() {
    return {
      loading: false,
      hireUsProducts: [],
      bus: new Vue(),
    }
  },
  computed: {
    slideDescription() {
      return DOMPurify.sanitize(this.slide.description)
    },
  },
  methods: {
    ...mapActions('checkout', ['fetchProduct']),

    async utilityClicked() {
      if (!this.slide.products.length > 0) {
        this.error()
        return
      }
      this.hireUsProducts = await this.fetchProduct({
        productKind: this.slide.products[0].product_kind,
        productCategory: this.slide.products[0].product_category,
        jurisdictionId: this.selectedJurisdiction.id,
      })

      if (!this.hireUsProducts.length) {
        this.error()
        return
      }
      this.bus.$emit('open-product-options-confirm', { products: this.hireUsProducts })
    },
    productOptionConfirmed() {
      this.$emit('utility-complete')
    },
    error() {
      this.$bvToast.toast('Unable to add product to cart at this time.', {
        title: 'Error',
        variant: 'danger',
        solid: true,
      })
      this.$emit('utility-complete')
    },
    async productAdded(productAdded) {
      await logSlideProductAdded(productAdded)
    },
  },
}
</script>

<style lang="scss" scoped>

#product-slide {
  .parent-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}


@media only screen and (max-width: 660px) {
  #product-slide {
    width: 100%;
  }
}

</style>
