var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "product-slide" } },
    [
      _c("slide-heading", {
        attrs: { heading: _vm.slide.heading, "tool-tip": _vm.slide.tool_tip },
      }),
      _vm.slide.image_urls.length && _vm.slide.description
        ? _c(
            "div",
            [
              _c("slide-image-and-description", {
                attrs: { slide: _vm.slide },
              }),
            ],
            1
          )
        : _vm.slide.description
        ? _c("div", [
            _c("div", { staticClass: "description" }, [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.slideDescription) },
              }),
            ]),
          ])
        : _vm.slide.image_urls.length
        ? _c("div", [
            _c("div", { staticClass: "image-vert" }, [
              _c("img", {
                attrs: { src: _vm.imageUrl, alt: _vm.slide.image_alt_text },
              }),
            ]),
          ])
        : _vm._e(),
      _c("product-options-confirm-modal", {
        attrs: { bus: _vm.bus, loading: _vm.loading },
        on: {
          complete: _vm.productOptionConfirmed,
          productAdded: _vm.productAdded,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }